<template>
  <div class="" v-if="providers.length">
    <h2 class="my-3">{{ $t('billing.refill-balance.title') }}</h2>
    <div class="d-flex align-items-center my-4">
      <b-form-select class="border w-auto" v-model="selectedAmount">
        <template v-slot:first>
          <b-form-select-option disabled :value="null">
            {{ $t('billing.refill-balance.select-amount') }}...
          </b-form-select-option>
          <b-form-select-option
            v-for="amount in providers[0].amountOptions"
            :key="amount.transactionAmount"
            :value="amount.paid"
          >
            {{ amount.label }}
          </b-form-select-option>
        </template>
      </b-form-select>
      <b-button
        variant="primary"
        size="sm"
        class="mx-3"
        @click="onAddAmount"
        :disabled="!selectedAmount"
      >
        {{ $t('billing.refill-balance.add') }}
      </b-button>
    </div>

    <b-spinner v-if="loading"></b-spinner>
    <div class="stripeForm" v-if="canPay">
      <stripe-element-payment
        ref="paymentRef"
        :pk="pk"
        :elements-options="elementsOptions"
        :confirm-params="confirmParams"
        @element-ready="onLoading"
        @error="onError"
        :locale="locale"
      />
      <b-button
        variant="primary"
        size="sm"
        class="my-3"
        :disabled="loading"
        @click="pay"
      >
        {{ $t('billing.refill-balance.pay-now') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import http from '../../axios'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    StripeElementPayment
  },
  computed: {
    ...mapGetters(['providers', 'currentJera']),
    locale() {
      return Vue.i18n.locale()
    }
  },
  data() {
    return {
      pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      elementsOptions: {
        appearance: {} // appearance options
      },
      confirmParams: {
        return_url: `${window.location.origin}`
      },
      selectedAmount: null,
      canPay: false,
      loading: false
    }
  },
  methods: {
    pay() {
      this.$refs.paymentRef.submit()
    },
    async onAddAmount() {
      try {
        this.loading = true
        this.canPay = false
        const res = await http.post(
          `${process.env.VUE_APP_NEW_API}jera/payment/stripe/create-intent`,
          {
            amount: this.selectedAmount
          }
        )
        this.elementsOptions.clientSecret = res.data.paymentIntent.clientSecret
        this.canPay = true
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    onError(error) {
      this.toast(error.message, {
        type: 'error'
      })
    },
    onLoading(something) {
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  border-radius: 0.5rem;
  border: 1px solid darken($brand-success, 5%);
}
.w-auto {
  width: auto !important;
}
</style>
